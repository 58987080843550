$lz-green: #008452;
$lz-green-40: #00845240;
$theme-colors: (
  'success': $lz-green,
  'primary': $lz-green,
);

@import '~bootstrap/scss/bootstrap';

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Arial', sans-serif;
}

.generator {
  background-color: #000;
  color: #fff;
  overflow: hidden;

  a:hover {
    color: $lz-green;
  }

  h1 {
    color: #fff;
  }
  form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      'name  phone'
      'title email'
      'logo  jurisdiction';
    grid-gap: 8pt;

    & > div:nth-child(2) {
      grid-area: title;
    }
    & > div:nth-child(1) {
      grid-area: name;
    }
    & > div:nth-child(3) {
      grid-area: phone;
    }
    & > div:nth-child(4) {
      grid-area: email;
    }
    & > div:nth-child(5) {
      grid-area: logo;
    }
    & > div:nth-child(6) {
      grid-area: jurisdiction;
    }
    label {
      font-size: 9pt;
    }
    div {
      display: flex;
      flex-direction: column;
      padding: 0.5em 0;

      input,
      select {
        background-color: #444;
        color: #eee;
        font-size: 14pt;
        padding: 8pt 4pt;
        border: none;
      }
    }
  }
  a {
    text-align: left;
    font-size: 11pt;
    text-decoration: none;
    color: #777;

    &:hover {
      color: $lz-green;
    }
  }
}
.generator > div {
  max-width: 40em;
  margin: 2em auto;
}
.wrapper > div {
  max-width: 400px;
  margin: 2em auto;
}
#version {
  text-align: left;
  font-size: 11pt;
  text-decoration: none;
  color: #777;
}
.copy-url button {
  background-color: transparent;
  color: $lz-green;
  font-size: 11pt;
  display: block;
  border: none;
  outline: none;
  cursor: pointer;
  text-align: left;
}
.code {
  position: relative;
  left: 85px;
  max-width: 40em;

  button {
    background-color: $lz-green;
    color: #fff;
    font-size: 16pt;
    font-weight: bold;
    padding: 0.6em 1.8em;
    border-radius: 4pt;
    margin: 1em auto 3em auto;
    display: block;
    border: none;
    outline: none;
    cursor: pointer;

    .btn-group {
      width: 323px;
      display: flex;
    }
  }

  #copySignature:hover {
    background-color: goldenrod;
  }
  label {
    font-size: 10pt;
  }
  textarea {
    display: block;
    min-height: 10em;
    width: 100%;
    font-family: monospace;
    padding: 1em;
  }
  label,
  textarea {
    opacity: 0.4;
  }
  &:hover label,
  &:hover textarea {
    opacity: 1;
  }
}

@import '~react-toastify/scss/main';
:root {
  --lz-green: #008452;
  --toastify-color-progress-light: var(--lz-green);
  --toastify-color-progress-dark: var(--lz-green);
}

.form-check-input:checked {
  background-color: $lz-green !important;
  border-color: $lz-green !important;
}

.form-check-input:focus {
  box-shadow: 0 0 0 0.25rem $lz-green-40;
}
